import { Box, Typography } from "@mui/material";

import { DefaultLoader } from "@/components/common/loading/DefaultLoader";

type LabelProps = {
  label: string;
  value?: string | number;
  show?: boolean;
  loading?: boolean;
};

export const Label = ({ label, value, show = true, loading }: LabelProps) => {
  if (!show || !label || typeof value === "undefined") {
    return null;
  }
  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      {loading && <DefaultLoader sx={{ width: "100%" }} height="18px" />}
      {!loading && (
        <Typography
          variant="body2"
          data-test={`header-${label.toLowerCase()}`}
          fontWeight="bold"
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};
