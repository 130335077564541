import { TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RiskDistributionHeaderCellProps {
  text: string;
}

export const RiskDistributionHeaderCell = (
  props: RiskDistributionHeaderCellProps
) => {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <TableCell
      style={{
        minWidth: "110px",
        textAlign: "center",
        borderRight: text === "Catastrophic" ? "" : "1px solid lightgray",
      }}
    >
      {t(text)}
    </TableCell>
  );
};
