import { Stack, Typography } from "@mui/material";

interface Props {
  shown: number;
  total: number;
}

export const HighRisksTableHeader = (props: Props) => {
  const { shown, total } = props;
  return (
    <Stack direction="row" px={0} py={1} alignItems="center" spacing={1}>
      <Typography variant="h2">Assets above thresholds</Typography>
      <Typography
        variant="subtitle2"
        fontWeight="normal"
      >{`(${shown}/${total})`}</Typography>
    </Stack>
  );
};
